import React, { useState } from "react";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "css/assets/mstile.png";
import { logout } from "actions/auth";

const RfuNavbar = ({ isAuthenticated, logout }) => {
  const [toggle, setToggle] = useState(false);

  const handleExpanded = () => {
    !toggle ? setToggle(true) : setToggle(false);
  };

  return (
    <Navbar
      className="navbar_box_shadow"
      expand="lg"
      expanded={toggle}
      onToggle={handleExpanded}
      bg="light"
      variant="light"
      sticky="top"
    >
      <Navbar.Brand className="u-margin-left-2">
        <span>
          <img className="logo" src={logo} alt=" RFuW Engineering Logo" />
        </span>{" "}
        RFuW Engineering
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        id="responsive-navbar-nav"
        className="rfu_navbar u-margin-right-5"
      >
        <Nav className="font__size-2 u-padding--1">
          {isAuthenticated && (
            <NavLink
              className="navbar__link text-center u-padding--05 u-margin-right-3"
              activeClassName="underline-navbar"
              exact
              to="/dashboard"
            >
              Dashboard
            </NavLink>
          )}

          <NavLink
            className="navbar__link text-center u-padding--05 u-margin-right-3"
            activeClassName="underline-navbar"
            exact
            to="/"
          >
            Home
          </NavLink>

          <NavDropdown
            bsPrefix={`navbar__link u-padding--05 u-margin-right-3 navbar__item`}
            title="Products"
            id="nav-dropdown"
          >
            <NavDropdown.Item
              eventKey="4.1"
              className="navbar__item-dropdown u-padding--05"
              as={NavLink}
              to="/switches"
            >
              RF Switches
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="4.2"
              className="navbar__item-dropdown"
              as={NavLink}
              to="/limiters"
            >
              RF Limiters
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="4.3"
              className="navbar__item-dropdown"
              as={NavLink}
              to="/colimiters"
            >
              Connectorized Limiters
            </NavDropdown.Item>
            <NavDropdown.Item
              eventKey="4.4"
              className="navbar__item-dropdown"
              as={NavLink}
              to="/swdrivers"
            >
              RF Switch Drivers
            </NavDropdown.Item>
          </NavDropdown>

          <NavLink
            exact
            to="/about"
            activeClassName="underline-navbar"
            className="navbar__link text-center u-padding--05 u-margin-right-3"
          >
            About Us
          </NavLink>

          <NavLink
            exact
            to="/news"
            activeClassName="underline-navbar"
            className="navbar__link text-center u-padding--05 u-margin-right-3"
          >
            News
          </NavLink>

          <NavLink
            exact
            to="/salerep"
            activeClassName="underline-navbar"
            className="navbar__link text-center u-padding--05 u-margin-right-3"
          >
            Sales Rep
          </NavLink>

          <Link
            to="#"
            onClick={(e) => {
              window.location.href =
                "mailto:sales@rfuw-engineering.com?subject=Contact%20Us&body=My%20name%20is%3A%20%0A%0ACompany%3A%20%0A%0AReason%20for%20contact%3A%20";
              e.preventDefault();
            }}
            activeClassName="underline-navbar"
            className="navbar__link text-center u-padding--05 u-margin-right-3"
          >
            Contact Us
          </Link>

          {isAuthenticated && (
            <NavLink
              exact
              to="/"
              onClick={logout}
              className="navbar__link text-center u-padding--05 u-margin-right-3"
            >
              Logout
            </NavLink>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps, { logout })(RfuNavbar);
