import logoImg from "css/assets/RFuWLogo_mnq73w.png";

export const switchesInitialState = {
  model: "",
  title: "",
  description: "",
  type: "switches",
  pdfUrl: "",
  dfxUrl: "",
  s2pUrl: "",
  buyNowUrl: "",
  featureProduct: false,
  insertionLoss: "",
  returnLoss: "",
  status: "",
  frequencyRange: "",
  switchTopology: "",
  averagePower: "",
  peakPower: "",
  iso: "",
  ip3: "",
  imageUrl: { logoImg },
  publicId: "News/RFuWLogo_qqly5e.png",
  tableSpace: "2",
};
export const limitersubutialState = {
  model: "",
  title: "",
  description: "",
  type: "limiters",
  pdfUrl: "",
  dfxUrl: "",
  s2pUrl: "",
  buyNowUrl: "",
  featureProduct: false,
  insertionLoss: "",
  returnLoss: "",
  status: "",
  limiterDescription: "",
  frequency: "",
  inputPowerCw: "",
  inputPowerPeak: "",
  flatLeakage: "",
  spikeLeakage: "",
  recoveryTime: "",
  imageUrl: { logoImg },
  publicId: "News/RFuWLogo_qqly5e.png",
  tableSpace: "2",
};
export const swDriversInitialState = {
  model: "",
  title: "",
  description: "",
  type: "swdrivers",
  pdfUrl: "",
  dfxUrl: "",
  s2pUrl: "",
  buyNowUrl: "",
  featureProduct: false,
  content: "",
  control: "",
  voltage1: "",
  voltage2: "",
  sinkMa: "",
  sourceMa: "",
  imageUrl: { logoImg },
  publicId: "News/RFuWLogo_qqly5e.png",
  tableSpace: "2",
};
export const coLimitersInitialState = {
  model: "",
  title: "",
  description: "",
  type: "colimiters",
  pdfUrl: "",
  dfxUrl: "",
  s2pUrl: "",
  buyNowUrl: "",
  featureProduct: false,
  insertionLoss: "",
  returnLoss: "",
  status: "",
  limiterDescription: "",
  frequency: "",
  inputPowerCw: "",
  inputPowerPeak: "",
  flatLeakage: "",
  spikeLeakage: "",
  recoveryTime: "",
  imageUrl: { logoImg },
  publicId: "News/RFuWLogo_qqly5e.png",
  tableSpace: "2",
};
