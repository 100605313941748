import logoImg from "css/assets/RFuWLogo_mnq73w.png";
export const images = ["News/dylan-nolte-NIrgENd0sAY-unsplash_snz2rg.jpg"];

export const newsFormFields = {
  title: "",
  content: "",
  type: "news",
  pdfUrl: "",
  imageUrl: { logoImg },
  publicId: "News/RFuWLogo_qqly5e.png",
};
