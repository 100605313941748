import React from "react";
import { Spinner } from "react-bootstrap";
const SpinerLoading = () => {
  return (
    <div className="spinner--box">
      <Spinner className="spinner--loading" animation="grow" role="status">
        <h2 className="sr-only">Loading...</h2>
      </Spinner>
    </div>
  );
};

export default SpinerLoading;
